import React from "react"
import styled from "styled-components"
import { graphql } from "gatsby"
import Layout from "@components/layout"
import Breadcrumb from "@components/blog/breadcrumb"
import Author from "@components/blog/author"
import Topics from "@components/blog/topics"
import BlogCard from "@components/blog/card"
import PropTypes from "prop-types"
import { ArticleContainer } from "@components/common/article"
import { Sticky } from "@components/common/article"
import Grid from "@components/elements/grid"
import Image from "@components/image"
import { formatSeo } from "@utils/format-seo"
import SquareColourfulCTA from "@components/cta/colourful/square"
import { HTMLWrapper } from "@components/common/html-wrapper"
import { StyledHTML } from "@components/layout/styled-html"

const Title = styled.h1`
  font-size: clamp(2rem, 3vw + 1rem, 3.5rem);
  line-height: 1.2;
  letter-spacing: -0.03em;
  color: #21264f;
  font-weight: 700;
  margin-bottom: 3rem;
`

const StyledBlog = styled.div`
  display: flex;
  flex-direction: column;
  .klipfolio-image-wrapper {
    justify-self: center;
    margin-left: auto;
    margin-right: auto;
  }
  li {
    font-size: 1.2rem;
    line-height: 2;
    margin: 0 0 15px;
  }
`
const Summary = styled.p`
  font-size: 1.2rem;
  line-height: 2;
  font-style: italic;
  letter-spacing: -0.02em;
  margin-top: 2rem;
`

const BlogHeader = styled.div`
  max-width: 900px;
  margin: 0 auto;
`

const Blog = ({ data: { blog }, pageContext }) => {
  const image = blog.image
  const author = blog.authors[0]
  const seo = formatSeo(
    blog.seo,
    blog.title,
    blog.summary,
    author,
    image,
    "article"
  )

  const date_created = new Date(blog.date)
  const date_updated = new Date(blog.date_updated)

  return (
    <Layout
      canonical={pageContext.slug}
      image={image.cdn}
      body={blog.body}
      date_created={blog.date}
      date_updated={blog.date_updated}
      seo={seo}
    >
      <article>
        <BlogHeader>
          <Breadcrumb
            links={[
              {
                text: "Blog",
                url: "/blog",
              },
              {
                text: blog.title,
              },
            ]}
          />
          <Title>{blog.title}</Title>
          {author && <Author author={author} />}
          <p style={{ marginBottom: "3rem" }}>
            Published <b>{date_created.toISOString().split("T")[0]}</b>, updated{" "}
            <b>{date_updated.toISOString().split("T")[0]}</b>
          </p>
          {image && (
            <Image radius={0.5} objectFit="fill" file={image} eager={true} />
          )}
          {blog.summary && (
            <Summary>
              <b>Summary -</b> {blog.summary.replace(/&#039;/g, "'")}
            </Summary>
          )}
          <hr />
        </BlogHeader>
        <ArticleContainer large>
          <div>
            <StyledBlog className={"blog"}>
              <StyledHTML>
                <HTMLWrapper
                  html={blog.body}
                  images={blog.body_images}
                  parseElements
                  cta
                />
              </StyledHTML>
            </StyledBlog>
            {blog.topics && <Topics topics={blog.topics} />}
            {blog.relatedBlogs && (
              <>
                <h2>Related Articles</h2>
                <Grid
                  columns="repeat(auto-fill, minmax(268px, 1fr))"
                  gap="30px"
                  margin="0 0 60px"
                >
                  {blog.relatedBlogs.map(blog => {
                    return (
                      <BlogCard
                        key={blog.id}
                        image={blog.image}
                        title={blog.title}
                        link={"/" + blog.slug}
                        author={blog.authors[0]?.name}
                        date={blog.date}
                      />
                    )
                  })}
                </Grid>
              </>
            )}
          </div>
          <Sticky>
            <SquareColourfulCTA />
          </Sticky>
        </ArticleContainer>
      </article>
    </Layout>
  )
}

Blog.propTypes = {
  data: PropTypes.object.isRequired,
  pageContext: PropTypes.object.isRequired,
}

export default Blog

export const pageQuery = graphql`
  query BlogPageQuery($id: String!) {
    blog(id: { eq: $id }) {
      title
      status
      id
      authors {
        name
        linkedin
        twitter
        profile_picture {
          id
          cdn
          placeholder
        }
      }
      seo {
        ...seoData
      }
      body
      category
      date
      date_updated
      summary
      topics
      image {
        id
        title
        cdn
        placeholder
      }
      relatedBlogs {
        title
        id
        status
        date(formatString: "MMMM Do, YYYY")
        slug
        image {
          id
          title
          cdn
          placeholder
        }
        authors {
          name
        }
      }
      body_images {
        cdn
        placeholder
        id
        directus_id
        title
      }
    }
  }
`
