import React from 'react'
import styled from 'styled-components'
import { Link } from 'gatsby'
import PropTypes from "prop-types"

const TopicContainer = styled.div`
  display: flex;
  gap: 5px;
  margin-bottom: 4rem;
  flex-wrap: wrap;
`

const TopicTag = styled(Link)`
  background-color: #f3f5fc;
  padding: 5px 10px;
  margin-right: 5px;
  font-weight: 700;
  border-radius: 1rem;
  color: #4b57c5;
  text-decoration: none;
  line-height: 1;
  display: flex;
  align-items: center;

  :hover, :focus {
    color: #343c8d
  }
`

const Topics = ({topics}) => {
  return (
    <TopicContainer>
      <b>Topics:</b>
      {topics.map((topic, i) => {
        return <TopicTag key={i} to={`/blog/${topic.toLowerCase().split(" ").join("-")}`}>{topic}</TopicTag>
      })}
    </TopicContainer>
  )
}

Topics.propTypes = {
  topics: PropTypes.array.isRequired,
}

export default Topics
